// extracted by mini-css-extract-plugin
export var olavsPane = "style-mod--olavs-pane--3lfHH";
export var olavsPaneDot = "style-mod--olavs-pane-dot--1WKqw";
export var pulse = "style-mod--pulse--1MW-Z";
export var olavsPaneImg = "style-mod--olavs-pane-img--12fTA";
export var olavsPaneContent = "style-mod--olavs-pane-content--2tbOI";
export var active = "style-mod--active--Lrklc";
export var olavsPaneText = "style-mod--olavs-pane-text--2rWBN";
export var olavsPaneTextImgContainer = "style-mod--olavs-pane-text-img-container--1GXRg";
export var olavsPaneTextImg = "style-mod--olavs-pane-text-img--2VOlu";
export var olavsPaneTextContent = "style-mod--olavs-pane-text-content--2nimd";
export var olavsPaneTitle = "style-mod--olavs-pane-title--1LxWm";
export var olavsPaneParagraph = "style-mod--olavs-pane-paragraph--1uwAu";
export var olavsPanePrice = "style-mod--olavs-pane-price--32q-n";
export var olavsPaneButton = "style-mod--olavs-pane-button--2F5f8";
export var olavsPanePriceSale = "style-mod--olavs-pane-price-sale--3I2o0";
export var olavsPaneButtonHolder = "style-mod--olavs-pane-button-holder--3Cjk9";
export var olavsPaneFryingPan = "style-mod--olavs-pane-frying-pan--1i5St";
export var olavsPanePanKnife = "style-mod--olavs-pane-pan-knife--NeT-c";
export var olavsPaneGeneralFryingPan = "style-mod--olavs-pane-general-frying-pan--SXyPb";
export var olavsPaneKnifeFryingPan = "style-mod--olavs-pane-knife-frying-pan--34Qrs";
export var olavsPaneWoodSpoon = "style-mod--olavs-pane-wood-spoon--sJ6MZ";