import React, { useState } from "react";
import FryingPan from "assets/images/fryingPan.png";
import PaneKnife from "assets/images/olavsPaneKnife.png";
import GeneralFryingPan from "assets/images/generalFryingPan.png";
import WoodSpoon from "assets/images/woodSpoon.png";
import KnifeFryingPan from "assets/images/knifeFryingPan.png";

import {
  olavsPane, olavsPaneContent, olavsPaneText,
  olavsPaneTextContent, olavsPaneTitle, active,
  olavsPaneParagraph, olavsPanePrice, olavsPaneButton,
  olavsPaneTextImgContainer, olavsPaneButtonHolder,
  olavsPaneDot, olavsPaneImg, olavsPaneTextImg,
  olavsPaneKnifeFryingPan, olavsPaneFryingPan,
  olavsPanePanKnife, olavsPaneGeneralFryingPan,
  olavsPaneWoodSpoon, olavsPanePriceSale
} from "./style.mod.scss";

const OlavsPaneContent = ({ data }) => {
  const [activeDot, setActiveDot] = useState(false);

  const typeClass = data.type[0]._type;
  const dotsList = data.type[0].productData;

  const componentProps = {
    "frying-pan" : { 
      class: olavsPaneFryingPan, 
      image: FryingPan 
    },
    "pan-knife" : { 
      class: olavsPanePanKnife, 
      image: PaneKnife
    },
    "general-frying-pan" : {
      class: olavsPaneGeneralFryingPan, 
      image: GeneralFryingPan 
    },
    "knife-frying-pan" : { 
      class: olavsPaneKnifeFryingPan, 
      image: WoodSpoon 
    },
    "wood-spoon" : { 
      class: olavsPaneWoodSpoon, 
      image: KnifeFryingPan
    },
  };

  const priceCurrency = "€";
  const componentClass = componentProps[typeClass]?.class;
  const componentImage = componentProps[typeClass].image;

  function setActiveIndexDot(_key) {
    setActiveDot(_key);
  }

  return (
    <div className={`${olavsPane} ${componentClass}`}>
      <img className={olavsPaneImg} src={componentImage} />
      {
        dotsList.map((dot) => {
          const { _key } = dot;
          return (
            <span
              onMouseOver={() => setActiveIndexDot(_key)}
              key={_key}
              className={olavsPaneDot}
            />
          );
        })
      }
      {
        dotsList.map((dotContent) => {
          console.log(dotContent, "dotThing");
          return (
            <div key={dotContent._key} className={`${olavsPaneContent} ${(activeDot === dotContent._key) ? active : ""}`}>
              <div className={olavsPaneText}>
                <div className={olavsPaneTextImgContainer}>
                  <img src={componentImage} className={olavsPaneTextImg} />
                </div>
                <div className={olavsPaneTextContent}>
                  <h4 className={olavsPaneTitle}>{dotContent.title}</h4>
                  <p className={olavsPaneParagraph}>{dotContent.text}</p>
                  <div className={olavsPanePrice}>
                    <span>{`${dotContent.price} ${priceCurrency}`}</span>
                    {
                      (dotContent.salePrice) &&
                      <span className={olavsPanePriceSale}>{`${dotContent.salePrice} ${priceCurrency}`}</span>
                    }
                  </div>
                </div>
              </div>
              <div className={olavsPaneButtonHolder}>
                <button className={olavsPaneButton}>SHOP NOW</button>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default OlavsPaneContent;
